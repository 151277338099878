<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:end>
                        <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
                    </template>
                </Toolbar>

                <DataTable exportFilename="relatorio_iptu[GUIAS_IMPLANTADAS]" csvSeparator=";" ref="dt"
                    :value="unidades" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50"
                    :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="
                        flex flex-column
                        md:flex-row md:justify-content-between md:align-items-center
                      ">
                            <h5 class="m-0">Guias Pendentes</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <Dropdown v-model="filters['id_empresa'].value" :options="empresas" showClear
                                    optionLabel="label" optionValue="value" placeholder="Selecione uma empresa">
                                </Dropdown>
                                <InputText v-model="filters['global'].value" placeholder="Filtar Resultado "
                                    class="mr-5" />|
                                <Dropdown id="status" v-model="mes" :options="meses" optionLabel="label"
                                    optionValue="value" placeholder="Selecione um mês" class="mr-2 ml-5">
                                </Dropdown>
                                <Dropdown id="status" v-model="ano" :options="anos" optionLabel="label"
                                    optionValue="value" placeholder="Selecione um ano" class="mr-2">
                                </Dropdown>
                                <Button label="Filtrar por Data" class="p-button-default bt-sm"
                                    @click="filtrarPorData" />
                            </span>
                        </div>

                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="razao_social" header="Empresa" :sortable="true"
                        headerStyle="width:14%; min-width:10rem;">
                    </Column>

                    <Column field="controle_re" header="Controle RE" :sortable="true"
                        headerStyle="width:30%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">controle_re</span>
                            {{ slotProps.data.controle_re }}
                        </template>
                    </Column>

                    <Column field="ordem_sap" header="Ordem SAP" :sortable="true"
                        headerStyle="width:30%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">ordem_sap</span>
                            {{ slotProps.data.ordem_sap }}
                        </template>
                    </Column>

                    <Column field="regional" header="Regional" :sortable="true" headerStyle=" min-width:10rem;">
                    </Column>

                    <Column field="cidade" header="Cidade" :sortable="true" headerStyle="width:30%; min-width:10rem;">
                    </Column>

                    <Column field="uf" header="UF" :sortable="true" headerStyle="width:30%; min-width:10rem;">
                    </Column>

                    <Column field="inscricao_imobiliaria" header="Inscrição Imobilária" :sortable="true"
                        headerStyle=" min-width:15rem;">
                    </Column>
                    <Column field="data_referencia" header="Data Referência" :sortable="true"
                        headerStyle=" min-width:15rem;">
                    </Column>

                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api"

export default {
    data() {
        return {
            token: localStorage.getItem('token'),
            meses: [
                { label: "Janeiro", value: "1" },
                { label: "Fevereiro", value: "2" },
                { label: "Março", value: "3" },
                { label: "Abril", value: "4" },
                { label: "Maio", value: "5" },
                { label: "Junho", value: "6" },
                { label: "Julho", value: "7" },
                { label: "Agosto", value: "8" },
                { label: "Setembro", value: "9" },
                { label: "Outubro", value: "10" },
                { label: "Novembro", value: "11" },
                { label: "Dezembro", value: "12" },
            ],
            anos: [
                { label: "2020", value: "2020" },
                { label: "2021", value: "2021" },
                { label: "2022", value: "2022" },
                { label: "2023", value: "2023" },

            ],
            mes: "",
            ano: "",
            dado_pendente: [
                { label: "Limpar Filtro", value: "" },
                { label: "Contrato RE", value: "1" },
                { label: "Inscrição Imobiliária", value: "2" },
                { label: "Ordem SAP", value: "3" },
                { label: "Endereço", value: "4" },
                { label: "Área", value: "5" },
                { label: "Tipo", value: "6" },
                { label: "Regional", value: "7" },
                { label: "Regra Pagamento", value: "8" },
            ],
            parcelas: [
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
                { label: "6", value: 6 },
                { label: "7", value: 7 },
                { label: "8", value: 8 },
                { label: "9", value: 9 },
                { label: "10", value: 10 },
                { label: "11", value: 11 },
                { label: "12", value: 12 },
            ],
            dado: "",
            arquivo: "",
            unidades: [],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            unidade: {},
            selectedProducts: null,
            filters: {},
            submitted: false,
            status: 0,
            empresas: [],
            areas: [],
            regionais: [],
            tipos: [],
            estados: [],
            cidades: [],
            id_estado: "",
            id_cidade: "",
            id_empresa: "",
            id_unidade: "",
            parcela: "",
            id_tipo: "",
            data_referencia: "",
            data_vencimento: "",
            total: "",
            boleto: "",
            faturas: [],
        }
    },
    productService: null,
    created() {
        this.initFilters()
    },
    mounted() {
        this.listarUnidades()
        this.listarEmpresa()
    },
    methods: {
        listarAreas() {
            this.axios
                .post("/areas/listar")
                .then((response) => {
                    let options = []
                    response.data.dados.map((el) => {
                        let option = { value: el["id"], label: el["titulo"] }
                        options.push(option)
                    })
                    this.areas = options
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        listarEmpresa() {
            this.axios
                .post("/empresas/listar")
                .then((response) => {
                    let options = []
                    response.data.dados.map((el) => {
                        options.push({ value: el["id"], label: el["razao_social"] })
                    })
                    this.empresas = options
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        trocarCidades() {
            console.log(this.unidade.id_estado)
            this.listarCidades(this.unidade.id_estado)
        },

        filtrar() {
            let dados = {
                filtro: this.dado,
            }
            this.axios
                .post("/unidades/listar", dados)
                .then((response) => {
                    if (Object.keys(response.data.dados).length == 0) {
                        this.unidades = [response.data.dados]
                    } else {
                        this.unidades = response.data.dados
                    }
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    })
                })
        },
        listarTipos() {
            this.axios
                .post("/tipos-faturas/listar")
                .then((response) => {
                    let options = []
                    response.data.dados.map((el) => {
                        let option = { value: el["id"], label: el["titulo"] }
                        options.push(option)
                    })
                    this.tipos = options
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        filtrarPorData() {
            let dados = {
                mes_referencia: this.mes,
                ano_referencia: this.ano
            }
            this.axios
                .post("/faturas/guias-pendentes", dados)
                .then((response) => {
                    this.unidades = response.data.dados
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    })
                })
        },

        listarUnidades() {
            this.axios
                .post("/faturas/guias-pendentes")
                .then((response) => {
                    this.unidades = response.data.dados
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    })
                })
        },

        formatCurrency(value) {
            if (value)
                return value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                })
            return
        },
        openNew() {
            this.unidade = {}
            this.submitted = false
            this.productDialog = true
        },
        hideDialog() {
            this.productDialog = false
            this.submitted = false
        },
        onChangeFileUpload() {
            this.arquivo = this.$refs.arquivo.files[0]
        },

        download(fatura) {
            let url = { ...fatura }
            window.open(url.link + "/" + this.token)
        },


        findIndexById(id) {
            let index = -1
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i
                    break
                }
            }
            return index
        },
        createId() {
            let id = ""
            var chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
            for (var i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length))
            }
            return id
        },
        exportCSV() {
            this.$refs.dt.exportCSV()
        },
        confirmDeleteSelected() {
            this.deleteProductsDialog = true
        },
        deleteSelectedProducts() {
            let obj = this.selectedProducts
            Object.keys(obj).forEach((key) => {
                this.axios
                    .post("/unidades/apagar", obj[key])
                    .then(() => {
                        this.listarFatura()
                        this.deleteProductDialog = false
                        this.unidade = {}
                        this.$toast.add({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Usário apagado com sucesso!s",
                            life: 3000,
                        })
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        })
                    })
            })

            this.deleteProductsDialog = false
            this.selectedProducts = null
            this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Faturas apagada com sucesso!",
                life: 3000,
            })
        },
        initFilters() {
            this.filters = {
                id_empresa: { value: null, matchMode: FilterMatchMode.EQUALS },
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            }
        },
    },
}
</script>

<style scoped lang="scss">
@use "../../assets/demo/badges.scss";
</style>
